.what-wrapper {
  position: relative;
  padding: 0px 0 60px 0;
}

.what-main {
  padding: 80px 100px;
  /* border: 1px solid #ccc; */
  display: flex;
  flex-direction: row;
  background-image: url(../../../public/images/What.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.what-main .what-left {
  flex-basis: 40%;
}
.what-main .what-right {
  flex-basis: 60%;
}

.what-main .regular-text {
  line-height: normal;
  letter-spacing: normal;
}

.what-right .block-title {
  text-indent: 100px;
  width: fit-content;
  margin: 0 0 0 auto;
}



@media (min-width: 1300px) and (max-width: 1399px){}

@media (min-width: 1200px) and (max-width: 1399px){
  .what-main .what-left { flex-basis: 30%; }
   .what-main .what-right { flex-basis: 70%; }
}

@media (min-width: 992px) and  (max-width: 1199px){
    .what-main { padding: 48px 48px 48px 74px;}
    .what-main .what-left { flex-basis: 30%; }
    .what-main .what-right { flex-basis: 70%; }
}

@media (max-width: 991px) {}

@media (min-width: 768px) and (max-width: 991px){
    .what-wrapper .what-main { padding: 38px 48px 38px 40px; }
   .what-right .block-title br {display: none;}
   .what-main .what-left { flex-basis: 30%; }
   .what-main .what-right { flex-basis: 70%; }
}

@media (min-width: 768px) {}

@media (max-width: 767px) {
    .what-wrapper {padding: 35px 0 60px 0;}
    .what-main { background-image: url(../../../public/images/WhatM.png); }
    .what-main .regular-text {text-align: center;}
    .what-main {flex-direction: column;padding: 28px 10px 48px 10px;gap: 48px;}
    .what-main .what-left {flex-basis: auto;}
    .what-main .what-right {flex-basis: auto;}
    .what-main .what-right .block-title {width: fit-content;margin: 0 auto;}
}

@media (min-width: 576px) and (max-width: 767px){}

@media (min-width: 240px) and (max-width: 575px){
    .what-right .block-title br {display: none;}
    .what-right .block-title { text-indent: 56px; }
}


@media (max-width:374px) {
    .what-right .block-title br {display: none;}

}